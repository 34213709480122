<template>
    <div>
        <div class="d-flex flex-wrap justify-content-around p-2">
            <div v-for="(p, idx) in list" v-bind:key="idx" class="m-1 align-self-center p-2">
                <b-img-lazy :src="computeImage(p)" :alt="p.name" class="partner-logo" width="90"/>
            </div>
        </div>
        <div>
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Brands",
    props: ['list'],
    methods: {
        computeImage(brand) {
            return require('@/assets/images/brands/' + brand.id + '.svg');
        }
    },
}
</script>

<style scoped lang="css">
    .partner-logo {
        min-width: 90px;
        max-width: 120px;
        max-height: 45px;
    }
</style>