<template>
    <TheBlock title="Nos solutions & contributions">
        <b-container>
            <b-card-group deck>
                <b-card no-body v-for="(item, index) in solutions" v-bind:key="index">
                    <b-card-header>
                        <b-link :href="item.link" target="_blank" rel="noopener" class="d-flex card-header-link justify-content-center">
                            <b-img-lazy class="d-block mr-2" :src="getImgUrl(item.logo)" :alt="item.name" width="32" v-if="item.logo !== null"/>
                            <span class="h3">{{ item.name }}</span>
                        </b-link>
                    </b-card-header>
                    <b-card-body>
                        <b-card-text class="text-justify">{{ item.description }}</b-card-text>
                    </b-card-body>
                </b-card>
            </b-card-group>
        </b-container>
    </TheBlock>
</template>

<script>
import TheBlock from "./_common/TheBlock";
export default {
    name: "Solutions",
    components: {TheBlock},
    data() {
        return {
            solutions: [
                {name: 'Daemonit', logo: 'logo_daemonit.png', link: 'https://daemonit.com/', description: 'Daemonit est un logiciel en SaaS qui permet d’auditer en quelques secondes le niveau de performance, de qualité et de sécurité de votre site web'},
                {name: 'SysAPI', logo: null, link: 'https://sysapi.org/', description: "SysAPI est un petit projet qui permet d'avoir des informations très utiles pour les administrateurs systèmes."},
                {name: 'TripleCrypto', logo: null, link: '#', description: 'Pour très ₿ientôt !'},
                {name: 'Ansistrano', logo: 'logo_ansistrano.svg', link: 'https://ansistrano.com/', description: "TripleStack est un sponsor du projet Ansistrano. Ce dernier sert à effectuer des déploiements d'applications."},
            ]
        }
    },
    methods: {
        getImgUrl: function (imagePath) {
            return require('@/assets/images/' + imagePath);
        }
    }
}
</script>

<style scoped>
    .card-header-link {
        height: 30px;
    }
</style>