import Vue from "vue";
import {
    ButtonGroupPlugin,
    ButtonPlugin,
    CardPlugin,
    CollapsePlugin,
    DropdownPlugin,
    ImagePlugin,
    LayoutPlugin,
    LinkPlugin,
    NavbarPlugin,
    NavPlugin,
    // TooltipPlugin,
    FormPlugin,
    FormGroupPlugin,
    FormTextareaPlugin,
    FormInputPlugin,
    InputGroupPlugin,
    AlertPlugin
} from "bootstrap-vue";

Vue.use(ButtonGroupPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(CollapsePlugin);
Vue.use(DropdownPlugin);
Vue.use(ImagePlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(NavPlugin);
Vue.use(NavbarPlugin);
// Vue.use(TooltipPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormInputPlugin);
Vue.use(InputGroupPlugin);
Vue.use(AlertPlugin);