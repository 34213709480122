import Vue from "vue";

async function loadFonts() {
    return import(
        /* webpackChunkName: "fa" */
        './font-awesome/svg-core'
        ).then(async (module) => {

            let fonts = await import(
                /* webpackChunkName: "fa" */
                './font-awesome/icons'
            );

            Object.keys(fonts).map(f => {
                module.library.add(fonts[f]);
            });
        }
    );
}

Vue.component('font-awesome-icon', () => import(
    /* webpackChunkName: "fa" */
    "./font-awesome/libs"
).then(async (module) => {
    await loadFonts();
    return module.FontAwesomeIcon;
}));