<template>
    <div class="text-center p-2 mx-4" v-if="myIcon !== null">
        <div>
            <font-awesome-icon size="4x" :icon="myIcon" />
        </div>
        <div class="h4 text-uppercase font-weight-bold mt-4 mb-3">{{ title }}</div>
        <p class="font-weight-bold font-sm">
            {{ sub_title }}
        </p>
    </div>
</template>

<script>
    export default {
        name: "ExpertiseBlock",
        props: {
            icon: {
                required: true
            },
            title: {
                type: String,
                required: true
            },
            sub_title: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                myIcon: null
            }
        },
        created() {
            this.myIcon = this.icon;
        }
    }
</script>

<style scoped>

</style>