<template>
    <TheBlock title="Contact">
        <b-container>
            <b-row align-h="around">
                <b-col md="6" cols="12" order="2" order-md="1">
                    <b-form @submit.prevent="postForm" v-if="!success">
                        <b-form-group label-cols="4" label-align="right" label="Votre nom" label-for="input-name">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text><font-awesome-icon fixed-width icon="user" /></b-input-group-text>
                                </template>
                                <b-form-input id="input-name" v-model="form.name" required></b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols="4" label-align="right" label="Votre adresse email" label-for="input-email">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text><font-awesome-icon fixed-width icon="at" /></b-input-group-text>
                                </template>
                                <b-form-input id="input-email" type="email" v-model="form.email" required></b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols="4" label-align="right" label="Votre message" label-for="input-message">
                            <b-input-group>
                                <template #prepend><b-input-group-text><font-awesome-icon fixed-width icon="comment" /></b-input-group-text></template>
                                <b-form-textarea id="input-message" rows="6" v-model="form.message" required></b-form-textarea>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group label-cols="4">
                            <b-button type="submit" :disabled="loading">
                                <font-awesome-icon fixed-width icon="spinner" class="mr-1" spin v-if="loading"/>
                                Envoyer
                            </b-button>
                        </b-form-group>

                        <b-alert variant="danger" :show="error_message !== null" dismissible fade>
                            Erreur : {{ error_message }}
                        </b-alert>

                    </b-form>
                    <b-alert show variant="success" v-else>
                        Votre message a bien été envoyé ! Nous vous contacterons très rapidement.
                    </b-alert>
                </b-col>

                <b-col md="4" cols="12" order="1" order-md="2">
                    <b-card no-body text-variant="dark">
                        <b-card-body>
                            <address class="d-block">
                                <p class="w-75 mx-auto">
                                    TripleStack SAS<br/>
                                    12 B rue du stade<br/>
                                    69290 Grezieu La Varenne<br/>
                                    FRANCE
                                </p>
                                <b-button-group class="d-block w-75 mx-auto">
                                    <b-button :href="'mailto:' + email" variant="primary">
                                        <font-awesome-icon fixed-width icon="at" class="mr-1 d-none d-sm-inline" />{{ email }}
                                    </b-button>
                                    <b-button :href="'tel:' + phone_number" variant="outline-primary" v-if="false">
                                        <font-awesome-icon fixed-width icon="phone" class="mr-1 d-none d-sm-inline"/>{{ phone_number }}
                                    </b-button>
                                </b-button-group>
                            </address>
                        </b-card-body>
                    </b-card>
                  <b-card no-body text-variant="dark">
                    <b-card-body class="text-center">
                      <b-button variant="secondary" href="https://support.triplestack.fr" target="_blank">
                        Accéder au support
                      </b-button>
                    </b-card-body>
                  </b-card>
                </b-col>
            </b-row>
        </b-container>
    </TheBlock>
</template>

<script>
    import TheBlock from "./_common/TheBlock";
    const axios_promise = import(/* webpackChunkName: "axios" */ 'axios');

    export default {
        name: "Contact",
        components: {
            TheBlock,
        },
        data() {
            return {
                phone_number: '+33 X XX XX XX XX',
                email: 'contact+site@triplestack.fr',
                axios: null,
                form: {
                    name: null,
                    email: null,
                    message: null
                },
                loading: true,
                error_message: null,
                success: false
            }
        },
        mounted() {
            axios_promise
                .then(x => this.axios = x)
                .then(async () => {
                    this.loading = false;
                })
            ;
        },
        methods: {
            async fetchToken() {
                const resp = await this.axios.get('/api/contact/token');
                return resp.data.token_value;
            },
            async postForm() {
                this.loading = true;
                this.error_message = null;
                const token = await this.fetchToken();
                const url = '/api/contact/form?_token=' + token;
                this.axios.post(url, this.form).then(() => {
                    this.success = true;
                }).catch((error) => {
                    this.error_message = error.response.data.error_message;
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>