<template>
    <b-container fluid class="theblock">
        <h2 v-if="title !== null">{{ title }}</h2>
        <slot></slot>
    </b-container>
</template>

<script>
export default {
    name: "TheBlock",
    props: {
        title: {
            type: String,
            required: false,
            default: null
        }
    }
}
</script>

<style lang="css">
    .theblock {
        padding: 4rem 0 4rem 0;
    }
</style>