<template>
    <TheBlock title="A propos">
        <b-container>
            <b-row>
                <b-col cols="12" md="6">
                    <p>TripleStack c'est 3 pôles d'expertise : l'infogérence, le développement et le pilotage.</p>
                    <p>Depuis 2019, nous accompagnons des <strong>startups</strong>, <strong>agences web</strong> et <strong>grands groupes</strong> dans leur projets informatique en utilisant principalement des technologies opensource.</p>
                    <p>Nous avons une grande expérience avec les <strong>sites web à forte charge</strong> et avec une exigence en sécurité : e-commerce, SaaS... etc. Nous intervenons pour vous en France et à l'international.</p>
                    <p>En 2020, nous avons lancé notre logiciel SaaS <b-link href="https://daemonit.com" rel="noopener" target="_blank" class="text-success font-weight-bold">Daemonit</b-link> qui effectue du monitoring et audits de sites web.</p>
                    <div class="text-center">
                        <b-button variant="primary" size="lg" href="#" v-scroll-to="'#contact'" class="mb-3 mb-md-0">
                            Nous contacter
                        </b-button>
                    </div>
                </b-col>
                <b-col cols="10" offset="1" offset-md="0" md="6">
                    <b-img-lazy
                        class="border border-dark" fluid-grow rounded
                        src="@/assets/images/code.jpg"
                        :srcset="require('@/assets/images/code.jpg') + ' 1920w, ' + require('@/assets/images/code_xs.jpg') + ' 500w'"
                        sizes="90vw"
                        alt="code triplestack"
                    />
                </b-col>
            </b-row>
        </b-container>
    </TheBlock>
</template>

<script>
import TheBlock from "./_common/TheBlock";

export default {
    name: "About",
    components: {TheBlock},
}
</script>

<style scoped>
    .jumb {
        background-image: url("../assets/images/datacenter.jpg") !important;
        background-repeat: no-repeat !important;
        background-size: 100% !important;
    }
</style>